import Button from '@integration-app/ui/Button'
import { Dialog } from '@integration-app/ui/Dialog'
import { HTMLAttributes, PropsWithChildren, useRef } from 'react'
import clsx from 'utils/clsx'
import classes from './SidePanel.module.css'

function SidePanelRoot({ children }: PropsWithChildren) {
  const ref = useRef<HTMLDivElement>(null)

  return (
    <div className={classes.wrapper} ref={ref}>
      <div className={classes.relWrapper}>
        <div className={classes.panel}>{children}</div>
      </div>
    </div>
  )
}

interface ISidePanelTitle extends HTMLAttributes<HTMLHeadingElement> {
  level?: 1 | 2 | 3
}

function SidePanelHeader({
  children,
  className,
  level = 1,
  ...props
}: ISidePanelTitle) {
  const Tag = getValueByLevel(level, 'h1', 'h2', 'h3')
  const levelClassName = getValueByLevel(
    level,
    classes.header,
    classes.subLevelHeader,
    classes.subSubLevelHeader,
  )
  return (
    <Tag className={clsx(levelClassName, className)} {...props}>
      {children}
    </Tag>
  )
}

function getValueByLevel<T>(
  level: ISidePanelTitle['level'],
  val1: T,
  val2: T,
  val3: T,
) {
  switch (level) {
    case 1:
      return val1
    case 2:
      return val2
    case 3:
      return val3
    default:
      return val1
  }
}

function SidePanelSubheader({
  children,
  className,
  ...props
}: HTMLAttributes<HTMLParagraphElement>) {
  return (
    <p className={clsx(className, classes.subheader)} {...props}>
      {children}
    </p>
  )
}

interface ISidePanelContent extends HTMLAttributes<HTMLDivElement> {
  grow?: boolean
}
function SidePanelContent({
  children,
  className,
  grow,
  ...props
}: ISidePanelContent) {
  return (
    <div
      className={clsx(classes.content, grow && classes.contentGrow, className)}
      {...props}
    >
      {children}
    </div>
  )
}

function SidePanelResetConfirmationModal({
  title = 'Want to reset?',
  description = 'This will erase user configuration for this element and replace it with default values. Are you sure?',

  onResetConfirm,
  onResetCancel,
}: {
  title?: string
  description?: string

  onResetConfirm: () => void
  onResetCancel: () => void
}) {
  return (
    <Dialog open={true} onClickOutside={onResetCancel}>
      <Dialog.Title>{title}</Dialog.Title>

      <Dialog.Description>
        <p>{description}</p>
      </Dialog.Description>

      <Dialog.ActionBar>
        <Button variant={'text'} onClick={onResetCancel}>
          No
        </Button>

        <Button onClick={onResetConfirm}>Yes</Button>
      </Dialog.ActionBar>
    </Dialog>
  )
}

const SidePanel = Object.assign(SidePanelRoot, {
  Header: SidePanelHeader,
  Subheader: SidePanelSubheader,
  Content: SidePanelContent,
  ResetConfirmationModal: SidePanelResetConfirmationModal,
})
export default SidePanel
