import { ValueChangeDetails } from '@zag-js/select'
import React, { useState } from 'react'
import { TbSelector } from 'react-icons/tb'
import { css } from 'styled-system/css'
import { Input } from 'ui-kit/input'
import { Select as SelectUiKit } from 'ui-kit/select'
import { Item, LoadingItem, ValueText } from './chunks'
import { ComboboxType, SelectItemType } from './types'

export function Combobox({
  label,
  placeholder,
  items,
  value,
  onValueChange,
  onSearchInputChange,
  loading,
  ItemTextComponent,
  positioning = {
    hideWhenDetached: true,
    sameWidth: true,
    strategy: 'fixed',
  },
  ...props
}: ComboboxType) {
  const [searchValue, setSearchValue] = useState('')

  const selectedValue = value ? [value.value] : undefined

  function handleValueChange({ items }: ValueChangeDetails<SelectItemType>) {
    onValueChange?.(items?.[0])
  }

  function handleSearchInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    const value = event.target.value
    setSearchValue(value)
    onSearchInputChange?.(value)
  }

  return (
    <SelectUiKit.Root
      items={items}
      value={selectedValue}
      onValueChange={handleValueChange}
      positioning={positioning}
      {...props}
    >
      {!!label && <SelectUiKit.Label>{label}</SelectUiKit.Label>}
      <SelectUiKit.Control>
        <SelectUiKit.Trigger>
          <ValueText
            value={value}
            placeholder={placeholder}
            ItemTextComponent={ItemTextComponent}
          />
          <TbSelector />
        </SelectUiKit.Trigger>
      </SelectUiKit.Control>
      <SelectUiKit.Positioner>
        <SelectUiKit.Content>
          <SelectUiKit.ItemGroup>
            <Input
              size={'sm'}
              value={searchValue}
              onChange={handleSearchInputChange}
              className={css({
                marginBlockEnd: 1,
                position: 'sticky',
                top: 0,
                zIndex: 'sticky',
                backgroundColor: 'bg.canvas',
              })}
            />
            {loading && <LoadingItem />}
            {items.map((item) => (
              <Item
                item={item}
                key={item.value}
                ItemTextComponent={ItemTextComponent}
              />
            ))}
          </SelectUiKit.ItemGroup>
        </SelectUiKit.Content>
      </SelectUiKit.Positioner>
    </SelectUiKit.Root>
  )
}
